<template>
    <div class="Footer">
      <div class="FooterSub">
        <div class="dtsumContent">
          <img src="~assets/img/Footer/LOGO@2x(1).png" alt="" />
  
          <div class="company">
            <div class="CompanyMaster">
              <div class="CompanyName">象来科技（舟山群岛新区）有限公司</div>
              <div class="CompanyAdd">
                浙江省舟山市岱山县高亭镇兰秀大道475号数字岱山创新大厦13层
              </div>
            </div>
            <div class="connect">
              <div class="connectUs">联系我们</div>
              <div class="consult">
                售前咨询：0571-88700015<br />
                商务合作：sanxiang@dtsum.com
              </div>
            </div>
            <!-- <div class="wechat">
              <img
                src="~assets/img/Footer/二维码@2x.png"
                alt=""
                class="erweima"
              />
              <div class="wechatText">
                <div class="wechatPub">微信公众号</div>
                <div class="scanWechat">扫一扫<br />了解更多咨讯</div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="footerState">
        <p class="dtsum">象来科技（舟山群岛新区）有限公司</p>
        <p class="state">免责声明</p>
        <p class="policy">隐私政策</p>
        <p class="security">浙公网安备 33011002015752号</p>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "Footer"
  };
  </script>
  <style lang="less" scoped>
  .Footer {
    background-color: #f8f9fb;
    .FooterSub {
      width: 1225px;
      height: 215px;
      border-top: 1px solid #e9eaec;
      border-bottom: 1px solid #e9eaec;
      margin: 0 auto;
      .dtsumContent {
        margin: 30px 0 0 44px;
        .company {
          margin-top: 20px;
          display: flex;
          .CompanyMaster {
            .CompanyName {
              width: 300px;
              height: 18px;
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #333333;
              opacity: 0.9;
            }
            .CompanyAdd {
              width: 300px;
              height: 44px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 28px;
              color: rgba(51, 51, 51, 0.45);
              margin-top: 20px;
            }
          }
          .connect {
            margin-left: 289px;
            .connectUs {
              width: 72px;
              height: 18px;
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              line-height: 20px;
              color: #333333;
              opacity: 0.9;
            }
            .consult {
              width: 222px;
              height: 44px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 28px;
              color: rgba(51, 51, 51, 0.45);
              margin-top: 20px;
            }
          }
        }
        .wechat {
          display: flex;
          margin-left: 208px;
          .erweima {
            width: 90px;
            height: 90px;
            margin-right: 15px;
          }
          .wechatText {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .wechatPub {
              width: 90px;
              height: 18px;
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #333333;
              opacity: 0.9;
            }
            .scanWechat {
              width: 84px;
              height: 42px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 28px;
              color: rgba(51, 51, 51, 0.45);
              margin-top: 20px;
            }
          }
        }
      }
    }
    .footerState {
      width: 1225px;
      display: flex;
      margin: 0 auto;
      justify-content: center;
      .dtsum {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 27px;
        color: rgba(51, 51, 51, 0.45);
        opacity: 1;
        margin-right: 16px;
      }
      .state {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 27px;
        color: rgba(51, 51, 51, 0.85);
        opacity: 1;
        margin-right: 16px;
      }
      .policy {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 27px;
        color: rgba(51, 51, 51, 0.85);
        opacity: 1;
        margin-right: 16px;
      }
      .security {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 27px;
        color: rgba(51, 51, 51, 0.45);
        opacity: 1;
      }
    }
  }
  </style>
  