<template>
  <div id="CustomerJXSponge">
    <!-- CustomerJXSponge头部内容 -->
    <div class="CustomerJXSpongeHeader">
      <!-- 头部组件 -->
      <Header />
    </div>
    <!-- CustomerJXSponge中间内容 -->
    <div class="CustomerJXSpongeCenter">
      <!-- 客户介绍 -->
      <div class="contentOne">
        <div class="titleOne">客户介绍</div>
        <div class="conInfo">
          <img src="~assets/img/CustomerJXSponge/客户@2x.png" />
          <div class="infoOne">
            岱山县金鑫海绵制品有限公司座落于浙江舟山群岛新区，是国内专业生产汽车座椅、座椅配件及聚氨酯制品的企业。致力于汽车行业和聚氨酯行业的发展与创新，具有较强的座椅等产品技术开发能力。具有先进的聚氨酯泡沫生产流水线，一体发泡成型汽车座椅头枕总成设备，完善的汽车座椅、座椅配件及国内先进的头枕杆专用生产线，和先进的检测设备。<br /><br />
            公司主要产品有各类汽车座椅、骨架、调角器及头枕杆、一体成型座椅头枕总成、各类聚氨酯泡沫﹑自结皮等系列产品。产品主要配套吉利集团整车座椅、上海岱美通用头枕杆及天津夏利汽车座椅调角器。
          </div>
        </div>
      </div>
      <!-- 项目价值 -->
      <div class="contentTwo">
        <div class="titleTwo">项目价值</div>
        <div class="titleT">
          2021年1月，金鑫海绵联合浙江三象数据有限公司打造了数字化工厂，项目被评为舟山市市级工业互联网示范项目。
        </div>
        <ul class="conBot">
          <li class="con1" v-for="(item, index) in conBots" :key="index">
            <div class="icon"></div>
            <div class="title1">{{ item.Title }}</div>
            <div class="contentO">
              {{ item.Text }}
            </div>
          </li>
        </ul>
      </div>
      <!-- 项目概况 -->
      <div class="contentThree">
        <div class="titleThree">项目概况</div>
        <ul class="imgContent">
          <li v-for="(item, index) in imgContents" :key="index">
            <img :src="item.Imgs" />
            <!-- <div class="imgBottom">{{ item.textPro }}</div> -->
          </li>
        </ul>
      </div>
    </div>
    <!-- 底部内容 -->
    <Footer />
    <!-- 回到顶部 -->
    <el-backtop
      ><img
        src="~assets/img/CustomerJXSponge/icon-一键置顶@2x.png"
        alt="icon-回到顶部@2x.png"
    /></el-backtop>
  </div>
</template>
<script>
import Header from "components/Header/dsHeader";
import Footer from "components/Footer/dsFooter";
export default {
  name: "CustomerJXSponge",
  data() {
    return {
      conBots: [
        {
          Title: "-工厂全面集成系统",
          Text:
            "建立一个从计划管理、采购管理、仓储管理、生产管理、质量控制等全面集成的系统。"
        },
        {
          Title: "-规范库存管理",
          Text: "通过系统应用规范库存管理，增加库存管理的透明度，降低库存成本。"
        },
        {
          Title: "-规范化的管理和体系",
          Text: "建立了规范的质量和安全信息的管理、采集、分析体系。"
        },
        {
          Title: "-丰富的生产分析类型",
          Text:
            "系统提供了多角度、全方位的生产状况查询功能和报表明细，提供决策支持信息；"
        },
        {
          Title: "-加强对生产过程的管理",
          Text:
            "对生产计划、进度、以及生产过程中的料、工、费信息进行有效的跟踪和控制，并准确及时的进行反馈。"
        }
      ],
      imgContents: [
        {
          Imgs: require("assets/img/CustomerJXSponge/冲压机车间@2x.png"),
          textPro: "冲压机车间"
        },
        {
          Imgs: require("assets/img/CustomerJXSponge/领导视察.jpg"),
          textPro: "2021年10月14日 岱山县委书记周国岭调研金鑫海绵数字化工厂项目"
        },
        {
          Imgs: require("assets/img/CustomerJXSponge/驾驶舱@2x.png"),
          textPro: "数字化工厂驾驶舱"
        }
      ]
    };
  },
    mounted() {  
      document.title = '岱山汽船配工业互联网平台';   
  },
  components: {
    Header,
    Footer
  }
};
</script>
<style lang="less" scope>
#CustomerJXSponge {
   width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;
  // CustomerJXSponge头部样式
  .CustomerJXSpongeHeader {
    width: 1583px;
    height: 620px;
    background: url("~assets/img/CustomerJXSponge/banner@2x.png") no-repeat;
    background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;
  }
  // CustomerJXSponge中间内容样式
  .CustomerJXSpongeCenter {
    width: 1220px;
    margin: 80px auto 101px;
    // 客户介绍
    .contentOne {
      .titleOne {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }
      .conInfo {
        margin-top: 30px;
        display: flex;
        img {
          width: 560px;
          height: 343px;
        }
        .infoOne {
          width: 614px;
          height: 157px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 28px;
          color: rgba(51, 51, 51, 0.85);
          margin: 33px 0 0 40px;
        }
      }
    }
    // 项目价值
    .contentTwo {
      margin-top: 60px;
      .titleTwo {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }
      .titleT {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 28px;
        color: rgba(51, 51, 51, 0.85);
        margin-top: 30px;
      }
      .conBot {
        width: 1220px;
        margin-top: 20px;
        padding: 0;
        display: flex;
        flex-direction: round;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          margin-bottom: 20px;
          width: 590px;
          height: 110px;
          background-color: #fff;
          position: relative;
          border-radius: 10px;
          &:nth-child(5) {
            height: 140px;
            .icon {
              height: 80px;
            }
            .contentO {
              width: 536px;
            }
          }
          .icon {
            width: 6px;
            height: 51px;
            background: #1e90ff;
            opacity: 1;
            border-radius: 0px 3px 3px 0px;
            display: inline-block;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
          }

          .title1 {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            line-height: 28px;
            color: #1e90ff;
            opacity: 1;
            display: inline-block;
            position: absolute;
            top: 20px;
            left: 20px;
          }

          .contentO {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 28px;
            color: #333333;
            opacity: 1;
            position: absolute;
            top: 60px;
            left: 20px;
          }
        }
      }
    }
    // 项目概况
    .contentThree {
      margin-top: 60px;
      .titleThree {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .imgContent {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;
        padding: 0;
        li {
          margin-bottom: 20px;
          background-color: #fff;
          &:nth-child(1) {
            width: 346px;
            height: 301px + 14px;
            img {
              width: 346px;
              height: 259px;
            }
          }
          &:nth-child(2) {
            width: 400px;
            height: 301px + 14px;
            img {
              width: 400px;
              height: 260px;
            }
            .imgBottom{
              text-align: left;
               box-sizing: border-box;
            padding: 10px 15px;
            }
          }
          &:nth-child(3) {
            width: 440px;
            height: 301px + 14px;
            img {
              width: 440px;
              height: 259px;
            }
          }

          .imgBottom {
            box-sizing: border-box;
            padding: 15px ;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.85);
            // margin-top: 15px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-backtop {
  img {
    width: 30px;
    height: 30px;
  }
}
</style>
