<template>
    <div class="header" :style="backgroudstyle">
      <div class="headerContent">
        <router-link :to="{ name: 'SolutionThreeDs' }">
          <div class="leftContent">
            <img
              v-if="activeIndex == 0"
              src="~assets/img/Header/LOGO@2x.png"
              alt=""
            />
            <img
              v-if="activeIndex == 1"
              src="~assets/img/Header/LOGOBlue.png"
              alt=""
            />
            <div class="dtsumName" :style="backgroudstyle">岱山汽船配工业互联网平台</div>
          </div></router-link
        >
        <div :class="activeIndex == 0 ? 'RightContent0' : 'RightContent1'">
          <el-menu class="el-menu-demo" mode="horizontal" text-color="#fff" >
            <el-menu-item index="6">
              <div @click="toDtsum">低代码开发平台</div></el-menu-item
            >
            <el-submenu index="3" >
              <template slot="title">开发者平台</template>
  
              <el-menu-item index="3-1" 
                ><router-link :to="{ name: 'LowCodePlatformDs' }">
                  低代码平台
                </router-link></el-menu-item
              >
  
              <el-menu-item index="3-2">
                <router-link :to="{ name: 'IOTPlatformDs' }" 
                  >IOT平台</router-link
                ></el-menu-item
              >
              <el-menu-item index="3-3" >
                <router-link :to="{ name: 'BIPlatformDs' }">
                  BI平台</router-link
                ></el-menu-item
              >
            </el-submenu>
            <el-submenu index="4">
              <template slot="title" class="title">案例精选</template>
              <el-menu-item index="4-1"
                ><router-link :to="{ name: 'CustomerLH' }"
                  >绿海制盐案例
                </router-link></el-menu-item
              >
              <el-menu-item index="4-2"
                ><router-link :to="{ name: 'CustomerRC' }"
                  >荣畅船舶案例
                </router-link></el-menu-item
              >
              <el-menu-item index="4-3"
                ><router-link :to="{ name: 'CustomerHS' }"
                  >惠生海洋案例
                </router-link></el-menu-item
              >
              <el-menu-item index="4-4"
                ><router-link :to="{ name: 'CustomerYL' }"
                  >舟山友联案例
                </router-link></el-menu-item
              >
              <el-menu-item index="4-5"
                ><router-link :to="{ name: 'CustomerNX' }"
                  >宁兴船舶案例
                </router-link></el-menu-item
              ><el-menu-item index="4-5"
                ><router-link :to="{ name: 'CustomerJS' }"
                  >捷胜海洋案例
                </router-link></el-menu-item
              ><el-menu-item index="4-5"
                ><router-link :to="{ name: 'CustomerBS' }"
                  >浙江宝塑案例
                </router-link></el-menu-item
              >
              <el-menu-item index="4-10"
                ><router-link :to="{ name: 'CustomerJXSpongeDs' }"
                  >金鑫海绵制品
                </router-link></el-menu-item
              >
            </el-submenu>
            <el-menu-item index="6">
              <router-link :to="{ name: 'ForUs' }">
              关于我们</router-link
                ></el-menu-item
            > 
            <el-menu-item index="6" class="AboutUs">
              <router-link :to="{ name: 'Board' }"
                >岱山汽船配工业互联网平台驾驶舱</router-link
              ></el-menu-item
            >
          </el-menu>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Header",
    data() {
      return {
        activeIndex: 1
      };
    },
    methods: {
      toDtsum() {
        window.open("http://qp.dtsum.com/user/login?redirect=%2F%23%2F", "_self");
      }
    },
    computed: {
      backgroudstyle() {
        return this.activeIndex === 1
          ? { background: "white", color: "#1e90ff" }
          : {};
      }
    },
    created() {
      // if (this.$route.path === "/Index") {
      //   this.activeIndex = 0;
      // } else {
      //   this.activeIndex = 1;
      // }
    }
  };
  </script>
  <style >
  .el-menu--collapse .el-menu .el-submenu, .el-menu--popup{
    min-width: 120px!important;
  }
</style>
  <style lang="less" scoped>
  .header {
    width: 100%;
    .headerContent {
      width: 1420px;
      height: 64px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .leftContent {
        width: 450px;
        height: 64px;
        line-height: 64px;
        display: flex;
        justify-content: space-between;
        img {
          width: 114px;
          height: 23px;
          margin-top: 22px;
          vertical-align: text-bottom;
        }
        .dtsumName {
          vertical-align: middle;
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          letter-spacing: 3px;
        }
      }
    }
  }
  </style>
  <style lang="less">
  .RightContent0 {
    .el-menu {
      padding: 0;
      background-color: transparent !important;
      border: none !important;
    }
    /* //二次菜单悬浮及背景样式 */
    .el-menu--popup-bottom-start .el-menu-item:hover {
      color: #3d7eff !important;
      background-color: #fff !important;
    }
    .el-menu--popup-bottom-start .el-menu-item {
      background: #fff !important;
      color: #7a8e9d !important;
    }
  
    /* //鼠标悬浮时，子菜单的样式 应用于只有一级的菜单*/
    .el-menu-item:hover {
      outline: 0 !important;
      color: #fff !important;
      background: none !important;
      border: none !important;
    }
    .el-menu-item {
      border: none !important;
      // padding: 0 !important;
    }
    .AboutUs {
      padding: 0 !important;
    }
  
    /* //鼠标悬浮时，主菜单的样式 应用于多级菜单的主菜单*/
    .el-submenu__title:focus,
    .el-submenu__title:hover {
      outline: 0 !important;
      color: #fff !important;
      background: none !important;
      border: none !important;
    }
    .el-submenu__title {
      color: #fff !important;
      border: none !important;
    }
    .el-icon-arrow-down:before {
      color: #fff;
    }
    .router-link-active {
      text-decoration: none;
    }
  }
  .RightContent1 {
    .el-menu {
      background-color: transparent !important;
      border: none !important;
      color: #1e90ff !important;
    }
    /* //二次菜单悬浮及背景样式 */
    .el-menu--popup-bottom-start .el-menu-item:hover {
      color: #3d7eff !important;
      background-color: #fff !important;
    }
    .el-menu--popup-bottom-start .el-menu-item {
      background: #fff !important;
      color: #7a8e9d !important;
    }
  
    /* //鼠标悬浮时，子菜单的样式 应用于只有一级的菜单*/
    .el-menu-item:hover {
      outline: 0 !important;
      color: #1e90ff !important;
      border: none !important;
    }
    .el-menu-item {
      border: none !important;
      color: #1e90ff !important;
      // padding: 0 !important;
    }
    .AboutUs {
      padding: 0 !important;
    }
    .el-submenu__title {
      color: #1e90ff !important;
      border: none !important;
    }
  
    /* //鼠标悬浮时，主菜单的样式 应用于多级菜单的主菜单*/
    .el-submenu__title:focus,
    .el-submenu__title:hover {
      outline: 0 !important;
      color: #1e90ff !important;
      background: none !important;
      border: none !important;
    }
    .el-icon-arrow-down:before {
      color: #1e90ff;
    }
    .router-link-active {
      text-decoration: none;
    }
  }
  </style>
  